export class Pattern {
	// eslint-disable-next-line
	static emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// eslint-disable-next-line
	static nameRegex = /^[A-Za-z\s]+$/;
}

export const HTTP_METHODS = {
	GET: 'get',
	POST: 'post',
	PATCH: 'PATCH',
	DELETE: 'delete'
}

export const API_ORIGIN = 'https://download.vodium.com/';

export const SITE_KEY_DEV = 'b09a9ae2-ec62-4fc0-8080-50207ded9fb9';
export const SITE_KEY_TEST = 'ee2b6097-2e66-4d5f-ac7b-94c90c94dfb0';
export const SITE_KEY_PROD = '6f2d8db7-dd89-47cb-96b1-c8e5e7f2ed00';
